export const menuData = [
  {
    title: '首页',
    name: 'index',
    path: '/',
    component: () => import('@/views/PackageComponent/FormClasses/searchCriteria.vue')
  },
  {
    title: '易建设',
    name: 'YJS',
    children: [
      {
        title: '里程碑计划',
        name: 'milestonePlan',
        path: '/milestonePlan',
        component: () => import('@/views/YJS/milestonePlan/index.vue') // eslint-disable-next-line
      },
      {
        title: '厂站实际进度执行填报',
        name: 'factoryProgressFilling',
        path: '/factoryProgressFilling',
        component: () => import('@/views/YJS/factoryProgressFilling/index.vue') // eslint-disable-next-line
      },
      {
        title: '工序配置',
        name: 'processConfiguration',
        path: '/processConfiguration',
        component: () => import('@/views/YJS/factoryProgressFilling/processConfiguration.vue') // eslint-disable-next-line
      },
      {
        title: '厂站进度填报数据分析',
        name: 'analyse',
        path: '/analyse',
        component: () => import('@/views/YJS/factoryProgressFilling/analyse.vue') // eslint-disable-next-line
      },
      {
        title: '工程树维护',
        name: 'engineeringTreeMaintenance',
        path: '/engineeringTreeMaintenance',
        component: () => import('@/views/YJS/engineeringTreeMaintenance/index.vue') // eslint-disable-next-line
      }
    ]
  },
  {
    title: 'ECharts图表',
    name: 'ECharts',
    children: [
      {
        title: '柱状图',
        name: 'bar',
        children: [
          {
            title: '井-水位图',
            name: '/bar/well',
            path: '/bar/well',
            component: () => import('@/views/ECharts/bar/well.vue')
          }
        ]
      },
      {
        title: '多折线混合图表',
        name: 'mixture',
        children: [
          {
            title: '多Y轴-堆叠柱状图',
            name: 'multipleYAxis',
            path: '/multipleYAxis',
            component: () => import('@/views/ECharts/mixture/multipleYAxis.vue')
          },
          {
            title: '井-水位图',
            name: 'well',
            path: '/well',
            component: () => import('@/views/ECharts/mixture/well.vue')
          }
        ]
      }
    ]
  },
  {
    title: '表格',
    name: 'Table',
    children: [
      {
        title: '树形表格',
        name: 'treeTable',
        path: '/treeTable',
        component: () => import('@/views/Table/treeTable.vue')
      }
    ]
  },
  {
    title: '大屏',
    name: 'BigScreen',
    children: [
      {
        title: '万宁大屏',
        name: '/largeScreen',
        path: '/largeScreen',
        component: () => import('@/views/BigScreen/largeScreen.vue')
      }
    ]
  },
  {
    title: '封装组件',
    name: 'PackageComponent',
    children: [
      {
        title: '表单类',
        name: '/PackageComponent/FormClasses',
        children: [
          {
            title: '搜索条件组',
            name: '/FormClasses/searchCriteria',
            path: '/FormClasses/searchCriteria',
            component: () => import('@/views/PackageComponent/FormClasses/searchCriteria.vue')
          }
        ]
      }
    ]
  }
];

function routesFN(data, routes = []) {
  for (const val of data) {
    if (val.children && val.children.length > 0) {
      routesFN(val.children, routes);
    } else {
      routes.push({
        path: val.path,
        name: val.name,
        meta: {
          title: val.title
        },
        component: val.component
      });
    }
  }
  return routes;
}

export const routesData = routesFN(menuData);
