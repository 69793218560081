<template>
  <div id='app'>
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style>
#app {
  width: 100vw;
  height: 100vh;
}

a {
  text-decoration: none !important; /* 去除默认的下划线 */
  outline: none !important; /* 去除旧版浏览器的点击后的外虚线框 */
  color: #000 !important; /* 去除默认的颜色和点击后变化的颜色 */
  font-size: 12px;
}

</style>
<style scoped lang='scss'>
</style>
