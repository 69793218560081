import Vue from 'vue';
import VueRouter from 'vue-router';
import { routesData } from '@/router/menu';

Vue.use(VueRouter);
const routes = [
  {
    title: '首页',
    name: 'index',
    path: '/',
    component: () => import('@/views/index.vue'),
    children: [...routesData]
  },
  {
    title: '搜索',
    name: 'searchCriteria',
    path: '/searchCriteria',
    component: () => import('@/views/PackageComponent/FormClasses/searchCriteria.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
