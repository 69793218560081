import Vue from 'vue';
import App from './App.vue';
import router from './router';
// 引入全局样式
import '../package/style/index.scss';
// 全局引入vxe-table
import VXETable from 'vxe-table';
import 'vxe-table/lib/style.css';
Vue.use(VXETable);
// 全局引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// 引入echarts
import echarts from 'echarts';
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
